import React, { useCallback, useState } from 'react';
import Style from './style.module.scss';
import cameraIcon from '../../assets/svg/camera.svg';
import defaultImage from '../../assets/camera.png';
import { useNotificationToastContext } from 'components/common/NotificationToast';
import CustomSpinner from 'components/Spinner';

type Props = {
  src?: string | undefined;
  alt?: string | undefined;
  onChangeHandler?: Function;
  inputId?: string | undefined;
  showText?: boolean;
  size?: string;
  loading?:boolean;
};

const Avatar = ({
  src,
  alt,
  onChangeHandler,
  inputId,
  showText = false,
  size = '',
  loading=false
}:
Props) => {
  const { showNotificationToast } = useNotificationToastContext();
  const handleClick = () => {
    document.getElementById((inputId || '') + 'image-input-avatar')?.click();
  };
  return (
    <>
      <CustomSpinner isLoading={loading}>
        <div>
          <div className={`${Style.avatarWrapper}`}>
            <img className={Style.profilePic} src={src || defaultImage} />
            <div className={Style.uploadButton} onClick={handleClick}>
              <img
                height={50}
                width={50}
                className={Style.iploadIcon}
                src={cameraIcon}
              />
            </div>
            <input
              id={(inputId || '') + 'image-input-avatar'}
              className={Style.fileUpload}
              type="file"
              accept="image/*"
              onChange={event => {
                const maxSizeInBytes = 4 * 1024 * 1024; // Maximum file size in bytes
                const selectedFile = event.target.files?.[0];
                const fileSizeInBytes = selectedFile.size;
                if (selectedFile && onChangeHandler) {
                  if (selectedFile && selectedFile.type.includes('image/')) {
                    if (fileSizeInBytes > maxSizeInBytes) {
                      showNotificationToast(
                        'The maximum allowed file size is 4 MB.',
                        'warning',
                      );
                    } else {
                      onChangeHandler(selectedFile);
                    }
                  } else {
                    showNotificationToast(
                      'Please select a valid image file for your pet.',
                      'warning',
                    );
                  }
                }
              }}
            />
          </div>
        </div>
      </CustomSpinner>
      <div onClick={handleClick} className={Style.avatarText}>
        {!src ? 'Add Photo' : 'Edit Photo'}
      </div>
    </>
  );
};

export default Avatar;
