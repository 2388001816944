import React, { useState } from 'react';
import { CheckboxElement } from '../../../common/Form/CheckboxElement';
import ButtonLoader from '../../../common/ButtonLoader';
import styles from './pauseReason.module.scss';

const reasons = [
  "I don't need more food at the moment",
  "I've had issues with my deliveries in the past",
  'My Furbaby has health issues and needs different food',
  "The cost of food doesn't work for me right now",
];

interface Props {
  onCancel: Function;
  onSave: Function;
}

export const PauseReason: React.FC<Props> = props => {
  const [reasonIds, setReasonId] = useState([]);
  const [otherReasonVisibility, setOtherReasonVisibility] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const [loading, setLoading] = useState(false);

  const selectReason = event => {
    const isChecked = event.target.checked;
    const value = event.target.value;
    if (isChecked) {
      setReasonId([...reasonIds, value]);
    } else {
      const reasonIdsCopy = [...reasonIds];
      const index = reasonIds.indexOf(value);
      reasonIdsCopy.splice(index, 1);
      setReasonId(reasonIdsCopy);
    }
  };

  const handleOtherReasonVisibility = event => {
    const isChecked = event.target.checked;
    if (!isChecked) {
      setOtherReason('');
    }
    setOtherReasonVisibility(isChecked);
  };

  const saveChanges = () => {
    setLoading(true);
    const selectedReasons = reasonIds.map(reasonId => reasons[reasonId]);
    if (otherReason) selectedReasons.push(otherReason);
    props.onSave(selectedReasons.join(','), setLoading);
  };

  return (
    <div className={styles.container}>
      <p>
        To help improve our services, please let us know why <br />
        you are pausing deliveries at this time
      </p>
      {reasons.map((reason, index) => (
        <CheckboxElement
          key={index}
          label={reason}
          name="reason"
          id={`reason${index}`}
          value={index}
          onChange={selectReason}
        />
      ))}
      <CheckboxElement
        label="Other"
        name="reason"
        id="reason5"
        value="-1"
        onChange={handleOtherReasonVisibility}
      />
      {otherReasonVisibility && (
        <input type="text" onChange={e => setOtherReason(e.target.value)} />
      )}
      <div className="text-center cust-modal-footer mgn-top-50">
        <ButtonLoader
          className="btn-def"
          type="button"
          onClick={() => saveChanges()}
          text="Save Changes"
          loading={loading}
        />

        {/* <div
          className={`${styles.cancel} text-primary`}
          onClick={() => props.onCancel()}
        >
          Cancel
        </div> */}
      </div>
    </div>
  );
};
