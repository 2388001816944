import React, { useState, useEffect, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import rec_img_1 from '../../../assets/rec_img_1.png';
import rec_img_2 from '../../../assets/rec_img_2.png';
import rec_img_3 from '../../../assets/rec_img_3.png';
import { navigate } from '@reach/router';
import { getSuggestedPlan, getSuggestedPlan2 } from '../../../services/suggestion.api';
import { PackageSuggestion, Pet, Subscription } from '../../../services/models';
import AppConstants from '../../../AppConstants';
import FeedingPlanModal from '../../SamplingEvent/FeedingPlanModal';
import Avatar from 'components/Avatar';
import { updatePet } from 'services/pets.api';

interface Props {
  key: number;
  pet: Pet;
  openDeleteModal: Function;
  subscription: Subscription;
  fetchPets?: Function;
  allProducts: any;
}

export const Furbaby: React.FC<Props> = props => {
  const [suggestion, setSuggestion] = useState({} as PackageSuggestion);
  const [showFeedingPlan, setShowFeedingPlan] = useState(false); //TODO: Change name
  const isDeletable = props.subscription.id ? false : true;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const suggesionPlan = await getSuggestedPlan2({
        age: props.pet.age,
        dgwght: parseFloat(props.pet.weight),
        body_type: props.pet.body_type,
        activity_level: props.pet.activity_level,
        food_percent: props.pet.food_percent,
        breed: props.pet.breed
      });
      setSuggestion(suggesionPlan);
    })();
    return () => {};
  }, []);

  const getClassNames = useCallback(
    () =>
      classNames({
        disabled: !isDeletable,
        'float-left': true,
        'btn-item': true,
      }),
    [isDeletable],
  );

  const handleSubscribe = async e => {
    e.preventDefault();
    setShowFeedingPlan(true);
  };
  const jsonToFormData = (json: any) => {
    const formData = new FormData();
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        formData.append(key, json[key]);
      }
    }
    return formData;
  };
  return (
    <div className="my-fur-item">
      <div className="row">
        <div className="col-sm-3 col-md-2">
          {/* <div className="name-item">{props.pet.name.charAt(0)}</div> */}
          <div className="name-item1">
            <Avatar
              loading={loading}
              size="small"
              inputId={(props?.pet?.id || '') + ''}
              onChangeHandler={async imageFile => {
                const formData = new FormData();
                formData.append('file', imageFile);
                const payload = {
                  ...props?.pet,
                  photo: imageFile,
                  // Other data in the payload, if any
                };
                if (props?.pet?.id && props?.fetchPets) {
                  setLoading(true);
                  await updatePet(props?.pet?.id, jsonToFormData(payload));
                  props?.fetchPets();
                  setTimeout(() => {
                    setLoading(false);
                  }, 3000);
                }
              }}
              src={props?.pet?.photo || ''}
              alt={props.pet.name.charAt(0)}
            />
          </div>
        </div>
        <div className="col-sm-9 col-md-10 pt-sm-mgn-ct">
          <div className="row">
            <div className="col-lg-5">
              <div className="txt-wrapper">
                <div className="row">
                  <div className="col-sm-6 col-lg-12">
                    <div className="name txt-capitalize">
                      {props.pet.name}
                      {!props.subscription.id && ' (Unsubscribed)'}
                    </div>
                    <div className="dets">
                      <span>
                        Age:{' '}
                        <span className="txt-capitalize">{props.pet.age}</span>
                        <br />
                        Weight:{' '}
                        {Math.round(parseFloat(props.pet.weight) * 10) / 10} kg
                        <br />
                        Lifestyle:{' '}
                        {
                          AppConstants.LIFESTYLE_DISPLAY[
                            props.pet.activity_level
                          ]
                        }
                        {props.pet.breed && (
                          <>
                            <br />
                            {'Breed: ' + props.pet.breed}
                          </>
                        )}
                        <br />
                        {props.subscription?.cal_per_day ? props.subscription.cal_per_day : suggestion.calories_per_day} calories per day
                        <br />
                        {props.pet.food_percent}% FMFB
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-12">
                    <div className="btns clearfix pet-btn-wrap">
                      <div
                        className="float-left btn-item"
                        onClick={() =>
                          navigate(`/account/dashboard/editpet/${props.pet.id}`)
                        }
                      >
                        Edit Details
                      </div>
                      <div
                        className={getClassNames()}
                        onClick={() => {
                          if (!isDeletable) return;
                          props.openDeleteModal(props.pet.id, props.pet.name);
                        }}
                      >
                        Delete Furbaby
                      </div>
                    </div>
                  </div>

                  {!props.subscription.id && (
                    <div className="col-sm-6 col-lg-12 mgn-bot-10">
                      <hr />
                      <h5>
                        <i>{props.pet.name} is not subscribed currently.</i>
                      </h5>
                      <button
                        className="btn-def btn btn-primary mgn-top-20"
                        onClick={handleSubscribe}
                      >
                        Add Subscription
                      </button>
                      <br />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-12">
                  <label>Recommended feeding plan</label>
                </div>
                <div className="col-sm-4 pt-rec-img-wrap">
                  <div className="img-txt">
                    <div className="img-wrap-or mgn-bot-10">
                      <img className="" src={rec_img_1} />
                    </div>
                    <div className="line2">
                      {props.subscription?.cal_per_day ? props.subscription.cal_per_day : suggestion.calories_per_day} calories /day
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 pt-rec-img-wrap">
                  <div className="img-txt">
                    <div className="img-wrap-or mgn-bot-10">
                      <img className="" src={rec_img_2} />
                    </div>
                    <div className="line2">{props.subscription?.food_per_day ? props.subscription.food_per_day : suggestion.food_per_day}g /day</div>
                  </div>
                </div>
                <div className="col-sm-4 pt-rec-img-wrap">
                  <div className="img-txt">
                    <div className="img-wrap-or mgn-bot-10">
                      <img className="" src={rec_img_3} />
                    </div>
                    <div className="line2">
                      {props.subscription?.cups_per_day ? props.subscription.cups_per_day : suggestion.cups_per_day} cups /day
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFeedingPlan && (
        <FeedingPlanModal
          allProducts={props?.allProducts || []}
          petDetails={props.pet}
          suggestion={suggestion}
          show={showFeedingPlan}
          closeModal={() => setShowFeedingPlan(false)}
          emailingOptionEnabled={false}
        />
      )}
    </div>
  );
};
