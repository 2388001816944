import React, { useState, useLayoutEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import close from '../../../assets/close.png';
import ButtonLoader from '../../common/ButtonLoader';
import { useSubscriptionContext } from '../../../pages/account';
import { formatDate, getTomorrowsDateObject } from '../../../utils';
import AppConstants from '../../../AppConstants';
import StatusMessage from '../../common/StatusMessage';
import { StatusType } from '../../../types';
interface Props {
  closeModal: Function;
  show: boolean;
  subscriptionId: string;
  onResumed: Function;
}

export const ResumeSubscriptionModal = props => {
  const [nextOrderDate, setNextorderDate] = useState(getTomorrowsDateObject());
  const [resuming, setResuming] = useState(false);
  const {
    resumeSubscription,
    getPetBySubscriptionId,
  } = useSubscriptionContext();
  const [status, setStatus] = useState({ message: '', type: StatusType.none });

  useLayoutEffect(() => {
    if (props.show) {
      setStatus({ message: '', type: StatusType.none });
    }
  }, [props.show]);

  const resumeSubscriptionFunc = async () => {
    setResuming(true);
    try {
      await resumeSubscription(
        nextOrderDate,
        props.subscriptionId,
        getPetBySubscriptionId(props.subscriptionId).id,
      );
      setResuming(false);
      props.onResumed();
      props.closeModal();
    } catch (ex) {
      setStatus({
        message:
          "Oops. We are sorry we couldn't handle the request. Please contact us or try again later.",
        type: StatusType.error,
      });
      setResuming(false);
    }
  };

  return (
    <Modal show={props.show} onHide={props.closeModal}>
      <Modal.Body>
        <div className="cust-modal-content-wrapper">
          <div className="modal-close pos-rel">
            <img onClick={e => props.closeModal()} src={close} />
            <div className="cust-modal-content">
              <div className="hdr text-center">
                <h2 className="mgn-bot-40">Resume Subscription</h2>
              </div>
              Select a date for the next Order:
              <DatePicker
                selected={nextOrderDate}
                onChange={setNextorderDate}
                minDate={getTomorrowsDateObject()}
                dateFormat={AppConstants.DATE_PICKER_FORMAT}
              />
              <div className="text-center cust-modal-footer mgn-top-50">
                <ButtonLoader
                  className="btn-def"
                  loading={resuming}
                  type="button"
                  text="Resume"
                  onClick={resumeSubscriptionFunc}
                />
              </div>
              <StatusMessage message={status.message} type={status.type} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
