import React from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import './pauseDuration.module.scss';

interface Props {
  pauseTime: number;
  nextOrderDate: string;
  onCancel: Function;
  onConfirm: Function;
  onGoBack: Function;
}
export const ConfirmPause: React.FC<Props> = props => {
  const { nextOrderDate, pauseTime, onConfirm, onGoBack } = props;
  const modifiedOrderDate =
    pauseTime === -1
      ? 'when you resume your orders.'
      : `on ${moment(nextOrderDate)
          .add('days', pauseTime)
          .format('DD/MM/YYYY')}`;

  return (
    <>
      <p className="text-center">
        Your next order will process {modifiedOrderDate}
      </p>

      <p className="text-center mgn-top-30">
        If you would like to receive an order before/after this date you can go back and amend or edit or resume at any time from your dashboard
      </p>
      <p className="text-center">
        Please note: changing the volume or frequency of your delivery may
        change your price.
      </p>
      <div className="text-center cust-modal-footer mgn-top-50">
        <Button className="btn-def" type="button" onClick={() => onConfirm()}>
          Confirm
        </Button>
        <Button className="btn-def" type="button" onClick={() => onGoBack()}>
          Go Back
        </Button>
      </div>
    </>
  );
};
