import React, { useCallback, useEffect, useRef, useState } from 'react';
import { navigate } from '@reach/router';
import { Button } from 'react-bootstrap';
import { Product } from 'services/models';
import { Subscription } from '../../../services/models/subscription';
import box from '../../../assets/box.png';
import product_placeholder from '../../../assets/product_placeholder.png';
import { NutritionalInfo } from '../../common/NutritionalInfo';
import { TreatsInfo } from '../../common/NutritionalInfo/TreatsInfo';
import { useSubscriptionContext } from '../../../pages/account';
import CancelSubscriptionComponent from '../ManageSubscription/CancelSubscription';
import styles from '../dashboard.module.scss';
import subStyles from './mySubscription.module.scss';
import { Addons } from './Addons';
import {
  navigateToDashboard,
  getProductInfoFromId,
  getProductImageUrl,
  fetchIdFromUrl,
  getImageSource,
  roundPrice,
  productList,
} from '../../../utils';
import editIcon from '../../../assets/icons/edit.svg';
import saveIcon from '../../../assets/icons/save.svg';
import cancelIcon from '../../../assets/icons/cancel.svg';
import CustomSpinner from 'components/Spinner';
import { useNotificationToastContext } from 'components/common/NotificationToast';
import InputNumber from 'components/InputNumber';
import plus from '../../../assets/svg/plus-circle-solid.svg';

// import "./style.css"
import prodStyles from './ProductList.module.scss';
import { getRecomendedProducts } from 'services/recommended.api';
import { useAppSelector } from 'state';
import { createSubscriptionTreat } from 'services/subscriptionTreats.api';
interface Props {
  subscription: Subscription;
  resumeSubscription: Function;
  pauseSubscription: Function;
}

export const SubscriptionComponent: React.FC<Props> = props => {
  const { subscription, resumeSubscription, pauseSubscription } = props;

  // TODO: What if there is no pet for the subscription?
  // This has caused an error.
  const [viewNutritionInfo, setNutritionInfoVisibility] = useState(false);
  const [
    viewCancelSubscriptionModel,
    setViewCancelSubscriptionModel,
  ] = useState(false);
  const { showNotificationToast } = useNotificationToastContext();

  const {
    getPetBySubscriptionId,
    cancelSubscriptionFunc,
    allProducts,
    fetchSubscriptions,
  } = useSubscriptionContext();

  if (!subscription) return null;

  const pet = getPetBySubscriptionId(subscription.id);

  const rearrangeProducts = () => {
    const liveProducts =
      subscription.products?.filter(treat => {
        const productId = fetchIdFromUrl(treat.product);
        const product = getProductInfoFromId(allProducts, productId);
        if (product && product.is_public) {
          return true;
        }
      }) || [];
    const restOfProducts =
      subscription.products?.filter(
        treat => !liveProducts?.find(t => t.id === treat.id),
      ) || [];
    subscription.products = [...liveProducts, ...restOfProducts];
  };

  const handleCancelSubscription = async reason => {
    await cancelSubscriptionFunc(subscription.id, {
      why_leaving: reason,
    });
    setViewCancelSubscriptionModel(false);
    navigateToDashboard('');
    showNotificationToast(
      'Your request for cancelling subscription has been completed successfully.',
    );
  };

  let pricePerDelivery = subscription.get_total;

  const showManageSubscription = () => {
    navigate(`/account/dashboard/managesubscription/${subscription.id}`);
  };
  const seeMoreAddons = () => {
    navigate(`/account/dashboard/managesubscription/${subscription.id}`, {
      state: {
        seeMore: true,
      },
    });
  };
  let productId = subscription?.product;
  let productQuantity = subscription.box_count;
  let suggestedProducts = subscription.treats || [];
  let mainSideProduct: any = false;

  if (!pet?.id && subscription.products && subscription.products[0]) {
    mainSideProduct = subscription.products[0];
    rearrangeProducts();
    productId = subscription.products[0].product
      ? fetchIdFromUrl(subscription.products[0].product)
      : productId;
    pricePerDelivery = subscription.products[0].price
      ? subscription.products[0].price
      : pricePerDelivery;
    productQuantity = subscription.products[0].quantity
      ? subscription.products[0].quantity
      : productQuantity;
    suggestedProducts = subscription.products.filter(
      (product, index) => index > 0,
    );
  }
  let sideMainProduct = null;
  let mainProduct =
    getProductInfoFromId(allProducts, productId) || ({} as Product);
  if (Object.keys(mainProduct).length === 0 && !pet?.id) {
    sideMainProduct =
      (subscription?.products || []).length > 0
        ? subscription?.products[0]
        : null;
  }
  const mainProductImgSrc = getImageSource(mainProduct);
  // const mainProductImg = !pet?.id
  //   ? mainProductImgSrc
  //     ? getProductImageUrl(mainProductImgSrc)
  //     : product_placeholder
  //   : box;
  const mainProductImg = mainProductImgSrc
    ? getProductImageUrl(mainProductImgSrc)
    : !pet?.id
    ? product_placeholder
    : box;

  const isPetSubscription = !!subscription?.pet;
  return (
    <>
      <div className="fmf-card-body">
        <div className="my-fur-wrapper order-card-wrapper">
          <div className="my-fur-item">
            <div
              className={`${styles.petName} ${subStyles.subscriptionControls}`}
            >
              <div>
                {pet && pet.name ? (
                  <span>
                    <span className="txt-capitalize">{pet.name}</span>'s
                    Subscription
                  </span>
                ) : (
                  <span>
                    Sides Subscription
                    {subscription.display_id && subscription.display_id > 1
                      ? ' ' + subscription.display_id
                      : ''}
                  </span>
                )}
                {!subscription.is_active ? <span>{' (Paused)'}</span> : null}
              </div>
              <div className={subStyles.actions}>
                {/* {subscription.treats?.length === 0 && ( */}
                <div className="mgn-top-10">
                  <Button
                    className="btn-small"
                    onClick={showManageSubscription}
                  >
                    + Add Products
                  </Button>
                </div>
                {/* )} */}
                <div className="mgn-top-10">
                  <Button
                    className="btn-small"
                    onClick={showManageSubscription}
                  >
                    Manage Orders
                  </Button>
                </div>
                {subscription.is_active ? (
                  <div className="mgn-top-10">
                    <Button
                      className="btn-small"
                      onClick={() => pauseSubscription()}
                    >
                      Pause Orders
                    </Button>
                  </div>
                ) : (
                  <div className="mgn-top-10">
                    <Button
                      className="btn-small-alt"
                      onClick={() => resumeSubscription()}
                    >
                      Resume Orders
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-3">
                <div className="img-wrapper">
                  <img
                    src={
                      sideMainProduct
                        ? sideMainProduct?.product_image
                        : mainProductImg
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3">
                <div className="txt-wrapper">
                  {
                    <div className="name">
                      {pet?.id
                        ? mainProduct?.upc?.includes('LAMB')
                          ? 'Dog Food Luxe Lamb'
                          : 'Chicken Dog Box'
                        : sideMainProduct
                        ? sideMainProduct.product_name
                        : mainProduct?.title}
                    </div>
                  }
                  <div className="dets">
                    <span>{`${productQuantity}x ${
                      sideMainProduct
                        ? sideMainProduct.product_name
                        : mainProduct?.title
                    }`}</span>
                    <br />
                    <span
                      className="txt-underline cur-pointer"
                      onClick={() => setNutritionInfoVisibility(true)}
                    >
                      {sideMainProduct
                        ? ''
                        : pet?.id
                        ? 'View Nutritional Info'
                        : 'View Info'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-3">
                <div className="txt-wrapper">
                  <div className="name">Frequency</div>
                  <div className="dets">
                    <span>
                      {!pet?.id && !mainProduct.is_public ? (
                        'Unavailable for next order'
                      ) : (
                        <EditableField
                          subscription={subscription}
                          value={subscription.frequency}
                        >
                          Every {subscription.frequency} days
                        </EditableField>
                      )}
                    </span>
                  </div>
                </div>
                {/* <div className="txt-wrapper col-hide-lg">
                  <div className="name">Cost</div>
                  <div className="dets">
                    {!pet?.id && !mainProduct.is_public ? (
                      'N/A'
                    ) : pricePerDelivery ? (
                      <span>{`$${pricePerDelivery.toFixed(
                        2,
                      )} per delivery`}</span>
                    ) : null}
                  </div>
                </div> */}
              </div>
              <div className="col-12 col-sm-3">
                <div className="txt-wrapper">
                  <div className="name">Price</div>
                  <div className="dets">
                    {!pet?.id && !mainProduct.is_public ? (
                      'N/A'
                    ) : pricePerDelivery ? (
                      <span>
                        {`$${pricePerDelivery.toFixed(2)} per delivery`}{' '}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-sm-4 col-lg-3">
                <div className="txt-wrapper">
                  <div className="btns clearfix">
                    
                  </div>
                </div>
              </div> */}
            </div>
            <Addons
              addons={suggestedProducts}
              subscriptionId={subscription.id}
              allProducts={allProducts}
            />
            <div
              className={`${subStyles.footerBox} ${subStyles.subscriptionControls}`}
            >
              <div className="price-split-box">
                <div className="split-value-row">
                  <h4 className="split-left-value">Sub total</h4>
                  <div className="split-right-value">${roundPrice(
                      subscription?.pet
                        ? subscription?.get_total_incl_treats
                        : subscription.get_total,
                    )}</div>
                </div>
                <div className="split-value-row">
                  <h4 className="split-left-value">Shipping charge </h4>
                  <div className="split-right-value">${roundPrice(subscription.new_shipping_charge)}</div>
                </div>
                { subscription.discount ? <div className="split-value-row">
                  <h4 className="split-left-value">Discount</h4>
                  <div className="split-right-value">${subscription.discount}</div>
                </div> : null}
                <div className="split-value-row total-value-row">
                  <h4 className="split-left-value">Total</h4>
                  <div className="split-right-value">
                    ${roundPrice(subscription.final_total)}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RecomendedProducts
                filterProductsIds={[productId]}
                isPetSubscription={isPetSubscription}
                filterProducts={suggestedProducts || []}
                addCallback={async (data, callback) => {
                  // return
                  await createSubscriptionTreat(
                    subscription.id,
                    data.url,
                    1,
                    isPetSubscription
                      ? Number(
                          subscription?.pet
                            .split('/')
                            .filter(it => it)
                            .splice(-1),
                        )
                      : undefined,
                    false,
                  );
                  await fetchSubscriptions();
                  // setTimeout(() => {
                  callback();
                  // }, 2000);
                  // callback();
                }}
                seeMore={seeMoreAddons}
              />
            </div>
          </div>
        </div>
      </div>
      {pet?.id ? (
        <NutritionalInfo
          show={viewNutritionInfo}
          closeModal={() => setNutritionInfoVisibility(false)}
          productUpc={mainProduct.upc}
          data={mainProduct?.nutritional_information}
        />
      ) : (
        <TreatsInfo
          show={viewNutritionInfo}
          closeModal={() => setNutritionInfoVisibility(false)}
          info={mainProduct}
        />
      )}
      {viewCancelSubscriptionModel && (
        <CancelSubscriptionComponent
          closeModal={() => setViewCancelSubscriptionModel(false)}
          cancelSubscription={handleCancelSubscription}
          show={viewCancelSubscriptionModel}
          subscriptionId={subscription.id}
        />
      )}
    </>
  );
};
const EditableField = (props: any) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(props?.value || 0);
  const [loading, setLoading] = useState(false);
  const {
    updateSubscriptionData,
    getPetBySubscriptionId,
    fetchSubscriptions,
  } = useSubscriptionContext();

  const { showNotificationToast } = useNotificationToastContext();
  const pet = getPetBySubscriptionId(props.subscription.id);
  const handleFrequencyUpdate = async () => {
    setLoading(true);
    await updateSubscriptionData(
      {
        frequency: Number(value),
      },
      props.subscription.id,
      pet.id,
    );
    setLoading(false);
    setEdit(false);
    fetchSubscriptions && (await fetchSubscriptions());
    showNotificationToast('Subscription frequency updated successfully');
  };
  return (
    <CustomSpinner isLoading={loading}>
      <span className={subStyles.editablefield}>
        {edit ? (
          <React.Fragment>
            <InputNumber
              min={2}
              value={value}
              onValueChange={(e: any) => {
                setValue(e);
              }}
            />
            <span style={{ display: 'flex' }}>
              <img
                onClick={() => {
                  if (!loading) handleFrequencyUpdate();
                }}
                className="cur-pointer"
                height={40}
                width={40}
                src={saveIcon}
              />
              <img
                onClick={() => {
                  setEdit(false);
                  setValue(props?.value || 0);
                }}
                className="cur-pointer"
                height={40}
                width={40}
                src={cancelIcon}
              />
            </span>
          </React.Fragment>
        ) : (
          <>
            <span
              onClick={() => setEdit(true)}
              className={subStyles.editButton}
            >
              {props.children}
              <img height={40} width={40} src={editIcon} />
            </span>
          </>
        )}
      </span>
    </CustomSpinner>
  );
};

const RecomendedProducts = ({
  filterProducts,
  addCallback,
  isPetSubscription,
  filterProductsIds,
  seeMore,
}) => {
  const [products, setProducts] = useState([]);
  const [adding, setAdding] = useState([]);
  const { recommendedEntities } = useAppSelector(state => state.products);
  useEffect(() => {
    let filterIds = (filterProducts || []).map(it =>
      Number(
        it.product
          .split('/')
          .filter((it: any) => it)
          .splice(-1),
      ),
    );
    (filterProductsIds || []).forEach(it => filterIds.push(it));
    if (isPetSubscription) {
      productList.forEach(it => filterIds.push(Number(it.value)));
    }
    filterIds = [...new Set(filterIds)];
    setProducts(recommendedEntities.filter(it => !filterIds.includes(it.id)));
  }, [filterProducts, recommendedEntities]);
  const containerRef = useRef(null);
  const scrollLeft = useCallback(() => {
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft - 200,
      behavior: 'smooth',
    });
  }, []);

  const scrollRight = useCallback(() => {
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft + 200,
      behavior: 'smooth',
    });
  }, []);
  if ((recommendedEntities || []).length === 0) return <></>;
  return (
    <div className="recommended-container">
      <div className={prodStyles.recommendedProductsTitle}>
        <span>Recommended Products</span>
      </div>
      {products.length ? (
        <div className={prodStyles.outerContainer}>
          <span className={prodStyles.scrollButton} onClick={scrollLeft}>
            &lt;
          </span>
          <div className={prodStyles.container} ref={containerRef}>
            {products.map((product, index) => {
              const image = product?.images?.[0]?.original || '';
              const productId = Number(
                product.url
                  .split('/')
                  .filter(it => it)
                  .splice(-1),
              );

              return (
                <CustomSpinner isLoading={adding.includes(productId)}>
                  <div
                    key={'recommended_' + index}
                    className={prodStyles.product}
                  >
                    <div
                      onClick={() => {
                        if (!adding.includes(productId)) {
                          let inProgress = [...adding];
                          inProgress.push(productId);
                          setAdding([...new Set(inProgress)]);
                          addCallback(product, () => {
                            let inProgress = [...adding];
                            const index = adding.indexOf(productId);
                            if (index > -1) {
                              inProgress.splice(index, 1);
                            }
                            setAdding(inProgress);
                            // console.log('completed', {id,status});
                          });
                        }
                      }}
                      className={prodStyles.addButton}
                    >
                      <span>+</span>
                    </div>
                    <img
                      src={image}
                      alt={product.title}
                      className={prodStyles.image}
                    />
                    <div className={prodStyles.nameContainer}>
                      <span className={prodStyles.name}>{product.title}</span>
                    </div>{' '}
                    <span className={prodStyles.price}>
                      ${(product.subscription_price || 0).toFixed(2)}
                    </span>
                  </div>
                </CustomSpinner>
              );
            })}
          </div>
          <span className={prodStyles.scrollButton} onClick={scrollRight}>
            &gt;
          </span>
        </div>
      ) : (
        <div
          className={`${prodStyles.outerContainer} ${prodStyles.centerText}`}
        >
          All recommended products added. More available. Click "See More."
        </div>
      )}
      <div className={prodStyles.seeMoreContainer}>
        <Button
          className="btn-small See more"
          onClick={() => {
            seeMore();
          }}
        >
          See more
        </Button>
      </div>
    </div>
  );
};
