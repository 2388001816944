import React, { useState } from 'react';
import { DeleteModal } from './DeleteModal';
import { Furbaby } from './Pet';
import { Pet } from '../../../services/models';
import { useSubscriptionContext } from '../../../pages/account';
import { useNotificationToastContext } from '../../common/NotificationToast';

interface Props {
  pets: Pet[];
  onDelete: Function;
}

const modalInitialState = {
  show: false,
  id: 0,
  name: '',
};

export const Pets = ({ allProducts }) => {
  const {
    pets,
    getSubscriptionByPetId,
    removePet,
    fetchPets,
  } = useSubscriptionContext();
  const { showNotificationToast } = useNotificationToastContext();
  const [modalState, setModalState] = useState(modalInitialState);
  const [isLoading, setLoadingStatus] = useState(false);
  const closeModal = () => {
    setModalState(modalInitialState);
  };

  const deleteProfile = async () => {
    setLoadingStatus(true);
    await removePet(modalState.id);
    setLoadingStatus(false);
    closeModal();
    showNotificationToast(`${modalState.name}'s profile deleted successfully.`);
  };

  const openDeleteModal = (id: number, name: string) =>
    setModalState({
      show: true,
      id,
      name,
    });
  const subscribedPets = pets.filter(pet =>
    Boolean(getSubscriptionByPetId(pet.id)),
  );
  return (
    <div className="fmf-card">
      <div className="fmf-card-hdr clearfix">
        <div className="float-left">
          <span>My Furbabies</span>
        </div>
      </div>
      <div className="fmf-card-body">
        <div className="my-fur-wrapper">
          {[
            ...pets
              .filter(it => it.is_subscribed)
              .sort((a, b) => a.name.localeCompare(b.name)),
            ...pets
              .filter(it => !it.is_subscribed)
              .sort((a, b) => a.name.localeCompare(b.name)),
          ].map(pet => {
            const subscription = getSubscriptionByPetId(pet.id) || {};
            return (
              <Furbaby
                key={pet.id}
                pet={pet}
                openDeleteModal={() => openDeleteModal(pet.id, pet.name)}
                subscription={subscription}
                fetchPets={fetchPets}
                allProducts={allProducts}
              />
            );
          })}
          <DeleteModal
            name={modalState.name}
            id={modalState.id}
            show={modalState.show}
            closeModal={closeModal}
            deleteProfile={deleteProfile}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
