import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { navigate } from '@reach/router';
import { DeleteTreatModal } from './DeleteTreatModal';
import { find } from 'lodash';
import product_placeholder from '../../../assets/product_placeholder.png';
import { TreatsInfo } from '../../common/NutritionalInfo/TreatsInfo';
import {
  fetchIdFromUrl,
  getProductImageUrl,
  getImageSource,
} from '../../../utils';
import { SubscriptionTreats, ProductInfo } from 'models';
import editIcon from '../../../assets/icons/edit.svg';
import saveIcon from '../../../assets/icons/save.svg';
import cancelIcon from '../../../assets/icons/cancel.svg';
import ToggleSwitch from 'components/ToggleSwitch';
import { updateSubscriptionTreat, deleteSubscriptionTreat } from 'services/subscriptionTreats.api';
import { useSubscriptionContext } from '../../../pages/account';
import { useNotificationToastContext } from 'components/common/NotificationToast';
import CustomSpinner from 'components/Spinner';
import subStyles from './mySubscription.module.scss';

interface Props {
  addon: SubscriptionTreats;
  subscriptionId: string;
  addonInfo: ProductInfo['nutritional_information'];
  allProducts: ProductInfo[];
}


const modalInitialState = {
  show: false,
  subscriptionId: 0,
  addonId: 0,
  addonName: ''

};

const Addon = (props: Props) => {
  const [viewNutritionInfo, setNutritionInfoVisibility] = useState(false);
  const [productInfo, setProductInfo] = useState({} as ProductInfo);
  const { addon, subscriptionId, allProducts, addonInfo } = props;
  const getProductInfo = async () => {
    if (addon.product) {
      const productId = fetchIdFromUrl(addon.product);
      const productInfo = find(allProducts, { id: productId });
      setProductInfo(productInfo);
    }
  };

  useEffect(() => {
    getProductInfo();
  }, [addon, allProducts]);

  const imgSrc = getImageSource(productInfo);
  const editButtonClasses = ['btn-item', 'text-right', 'mgn-0'];
  if (!productInfo?.is_public) {
    editButtonClasses.push('disabled');
  }
  const ImgFallback = ({ src="", fallbackSrc="", alt="" }) => {
    const [imgSrc, setImgSrc] = useState(src);
  
    const handleImageError = () => {
      setImgSrc(fallbackSrc);
    };
  
    return (
      <img
        src={imgSrc}
        alt={alt}
        onError={handleImageError}
        style={{ maxWidth: '100%' , maxHeight: '140px'}}
      />
    );
  };
  return (
    <>
      <div className="my-fur-wrapper order-card-wrapper mgn-top-20">
        <div className="my-fur-item">
          <div className="row">
            <div className="col-12 col-sm-3">
              <div className="img-wrapper">
                <ImgFallback
                  src={addon?.product_image || product_placeholder}
                  fallbackSrc={product_placeholder}
                />
              </div>
            </div>
            <div className="col-12 col-sm-3">
              <div className="txt-wrapper">
                <div className="name">{addon.product_name}</div>
                <div className="dets">
                  <span>
                    {addon.quantity} x {addon?.product_name}
                  </span>
                  <br />
                  <span
                    className="txt-underline cur-pointer"
                    onClick={() => setNutritionInfoVisibility(true)}
                  >
                    {addon?.is_public?"View Info":""}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3">
              <div className="txt-wrapper">
                <div className="name">Frequency</div>
                <div className="dets">
                  <span>
                    {addon?.is_public ? (
                      <RadioEditableField
                        addon={addon}
                        subscriptionId={subscriptionId}
                        value={addon.is_once}
                      >
                        {addon.is_once ? 'Only once' : 'Every order'}
                      </RadioEditableField>
                    ) : (
                      'Unavailable for next order'
                    )}
                  </span>
                </div>
              </div>

              <div className="txt-wrapper">
                <div className="name">Cost</div>
                <div className="dets">
                  <span>
                    {productInfo?.is_public
                      ? `$${addon.unit_price} per unit`
                      : 'N/A'}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3">
              <div className="txt-wrapper">
                <div className="name">Price</div>
                <div className="dets">
                  <span>
                    {productInfo?.is_public
                      ? `$${addon.price} per delivery`
                      : 'N/A'}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-lg-2">
              <div className="txt-wrapper">
                <div className="btns clearfix">
                  <div
                    className={editButtonClasses.join(' ')}
                    onClick={() =>
                      productInfo?.is_public &&
                      navigate(
                        `/account/dashboard/managesubscription/${subscriptionId}`,
                      )
                    }
                  >
                    Edit Product
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TreatsInfo
        show={viewNutritionInfo}
        closeModal={() => setNutritionInfoVisibility(false)}
        info={productInfo}
      />
      
    </>
  );
};

const RadioEditableField = (props: any) => {
  const [edit, setEdit] = useState(true);
  const { value: newValue, subscriptionId, children, addon } = props;
  const [value, setValue] = useState(newValue ? 'only_once' : 'every_order');
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(modalInitialState);
  const { getPetBySubscriptionId,fetchSubscriptions } = useSubscriptionContext();
  const {
    showNotificationToast,
  } = useNotificationToastContext();
  const pet = getPetBySubscriptionId(subscriptionId);
  const [isLoading, setLoadingStatus] = useState(false);
  const closeModal = () => {
    setModalState(modalInitialState);
  };

  const openDeleteModal = (subscriptionId: number, addonId: number, addonName: string) =>
    setModalState({
      show: true,
      subscriptionId,
      addonId,
      addonName
    });

  const updateTreat = async (value: any) => {
    setLoading(true);
    await updateSubscriptionTreat(
      subscriptionId,
      addon.product,
      addon.quantity,
      addon.id,
      pet.id,
      value === 'only_once',
    );

    setLoading(false);
    setEdit(true);
    fetchSubscriptions && (await fetchSubscriptions());
    showNotificationToast('Addon updated successfully');
  };
  
  // Function to remove add-ons
  const removeTreat = async () => {
    setLoading(true);
    await deleteSubscriptionTreat(
      subscriptionId,
      addon.id,
      pet.id,
    );
    setLoading(false);
    fetchSubscriptions && (await fetchSubscriptions());
    showNotificationToast('Treat removed successfully');
  };
  return (
    <CustomSpinner isLoading={loading}>
      <span
        className={subStyles.editablefield}
      >
        {edit ? (
          <React.Fragment>
            <ToggleSwitch
              defaultValue={value}
              options={[
                { label: 'Every order', value: 'every_order' },
                { label: 'Only once', value: 'only_once' },
              ]}
              onChange={(e: string) => {
                setValue(e);
                updateTreat(e);
              }}
            />
            <span style={{display:'flex'}}>
            </span>
          </React.Fragment>
        ) : (
          <>
            <span onClick={() => setEdit(true)} className={subStyles.editButton}>
            {children}
              <img
                height={40}
                width={40}
                src={editIcon}
              />
            </span>
          </>
        )}
        <Button
        className="btn-txt btn-txt-remove-item"
        onClick={() => openDeleteModal(subscriptionId, addon.id, addon.product_name)}
      >
        Remove
      </Button>
      </span>
      <DeleteTreatModal
            subscriptionId={modalState.subscriptionId}
            addonId={modalState.addonId}
            addonName={modalState.addonName}
            show={modalState.show}
            closeModal={closeModal}
            removeTreat={removeTreat}
            isLoading={isLoading}
          />
    </CustomSpinner>
  );
};

export const Addons = props => {
  const { addons = [], subscriptionId, allProducts } = props;
  const [modalState, setModalState] = useState(modalInitialState);
  const closeModal = () => {
    setModalState(modalInitialState);
  };
  return addons.map((addon: SubscriptionTreats, index: number) => {
    const productUrl = addon.product;
    const matches = productUrl.match(/(\d+)/);
    let addonInfo = {} as ProductInfo['nutritional_information'];
    if (matches !== null) {
      const productId = Number(matches[0]);
      const product =
        allProducts.find((product: ProductInfo) => product.id === productId) ||
        {};
      addonInfo = product?.nutritional_information;
    }

    return (
      <Addon
        addon={addon}
        key={index}
        subscriptionId={subscriptionId}
        allProducts={allProducts}
        addonInfo={addonInfo}
      />
    );
  });
};