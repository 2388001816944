import React from 'react';

import { Button } from 'react-bootstrap';
type Props = {
  value: number;
  min?: number | null;
  onValueChange: Function;
  className?: string;
  max?: number | null;
};
const InputNumber = ({
  value: newValue,
  onValueChange,
  min = null,
  className = '',
  max = null,
}: Props) => {
  const value = newValue || 0;
  return (
    <div className={`number_input_control ${className}`}>
      <Button
        className="btn-decrement"
        type="button"
        disabled={min === null ? false : value === min}
        onClick={() => {
          // if (value > min) {
          onValueChange(value - 1);
          // }
        }}
      >
        <b>-</b>
      </Button>
      <span className="counter">{value}</span>
      <Button
        {...(max ? { disabled: value === max } : {})}
        className="btn-increment"
        type="button"
        onClick={() => {
          onValueChange(value + 1);
        }}
      >
        <b>+</b>
      </Button>
    </div>
  );
};
export default InputNumber;
