import React from 'react';
import { RadioButton } from '../../../common/Form/RadioButton';
import { Button } from 'react-bootstrap';
import styles from './pauseDuration.module.scss';

interface Props {
  pauseTime: number;
  handleChange: Function;
  onCancel: Function;
  onSave: Function;
}
export const PauseDuration: React.FC<Props> = props => {
  return (
    <>
      <p className="text-center">
        How long would you like to pause delivery for?
      </p>
      <div className={styles.buttonContainer}>
        <div className="row">
          <RadioButton
            className='customWidth'
            name="pauseTime"
            id="week"
            value="7"
            checked={props.pauseTime === 7}
            onChange={props.handleChange}
            label="1 Week"
          />
          <RadioButton
            name="pauseTime"
            id="2week"
            value="14"
            checked={props.pauseTime === 14}
            onChange={props.handleChange}
            label="2 Weeks"
          />
        </div>
        <div className="row">
          <RadioButton
            className='customWidth'
            name="pauseTime"
            id="1month"
            value="30"
            checked={props.pauseTime === 30}
            onChange={props.handleChange}
            label="1 Month"
          />
          <RadioButton
            name="pauseTime"
            id="unlimit"
            value="-1"
            checked={props.pauseTime === -1}
            onChange={props.handleChange}
            label="Until I restart deliveries"
          />
        </div>
      </div>

      <p className="text-center mgn-top-30">
        We will send you an email remainder a few days
        {/* <br /> */}
        before your delivery is due for dispatch
      </p>
      <div className="text-center cust-modal-footer mgn-top-50">
        <Button
          className="btn-def"
          type="button"
          onClick={() => props.onSave()}
        >
          Save Changes
        </Button>
        {/* 
        //Cancel not required as can be misleading
        <div
          className={`${styles.cancel} text-primary`}
          onClick={() => props.onCancel()}
        >
          Cancel
        </div> */}
      </div>
    </>
  );
};
