import React from 'react';
import Modal from 'react-bootstrap/Modal';
import close from 'assets/close.png';
import { ProductInfo, Product } from 'models';
import styles from './index.module.scss';
import { getProductImageUrl, getImageSource } from '../../../utils';
import product_placeholder from '../../../assets/product_placeholder.png';

interface Props {
  show: boolean;
  closeModal: Function;
  info: ProductInfo | Product;
}

export const TreatsInfo = (props: Props) => {
  const productImage = getImageSource(props.info);
  return (
    <Modal show={props.show} onHide={props.closeModal} size="lg">
      <Modal.Body>
        <div className="cust-modal-content-wrapper">
          <div className="modal-close pos-rel">
            <img onClick={e => props.closeModal()} src={close} />
          </div>
          <div className="cust-modal-content">
            <div className="hdr text-center">
              <h2 className="mgn-bot-40">{props.info?.title}</h2>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <img
                  src={
                    productImage
                      ? getProductImageUrl(productImage)
                      : product_placeholder
                  }
                  className={styles.treatsInfoImage}
                />
              </div>
              <div
                className="col-12 col-lg-6"
                dangerouslySetInnerHTML={{ __html: props?.info?.description }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
