import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
interface option {
  label: string;
  value: any;
}
type Props = {
  options: option[];
  defaultValue?: string;
  onChange: (value: string) => void;
  loading?: boolean;
  className?:string;
};

const ToggleSwitch = ({ options = [], defaultValue, onChange,className }: Props) => {
  const [selected, setSelected] = useState(defaultValue || '');
  if (!options.length) {
    return <></>;
  }
  return (
    <div className={`toggleSwitchContainer ${className}`}>
      <div className="radio_container">
        {options.map((it, index) => (
          <span key={'toggle_switch' + index}>
            <input
              type="radio"
              name="radio"
              value={it.value}
              checked={selected === it.value}
            />
            <label
              className={selected === it.value ? 'labelchecked' : 'cur-pointer'}
              onClick={() => {
                if (selected !== it.value) {
                  setSelected(it.value);
                  onChange(it.value);
                }
              }}
              htmlFor="one"
            >
              {it.label}
            </label>
          </span>
        ))}
      </div>
    </div>
  );
};

export default ToggleSwitch;
