import React, { useState, Suspense, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { useAppDispatch, useAppSelector, setAppView } from '../state';
import { AppView } from '../types';
import '../styles/custom.scss';
import '../styles/main.scss';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-input-range/lib/css/index.css';
import { AccountHeader } from '../components/AccountHeader';
import { Footer } from '../components/Footer';
import { HeaderOffer } from '../components/HeaderOffer';
import { isBrowser } from '../utils';
import Loader from 'components/common/Loader';
import ErrorBoundary from 'components/ErrorBoundary';

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props) => {
  const [mobileNavOpen, setMobileVisibility] = useState(false);
  const appView = useAppSelector(state => state.appState.appView);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location.pathname.includes('account') && appView !== AppView.ACCOUNT) {
      dispatch(setAppView(AppView.ACCOUNT));
    }
  }, [location.pathname, appView]);

  return (
    <ErrorBoundary><div
      className="page-wrapper account-wrapper"
      style={{ position: mobileNavOpen ? 'fixed' : 'static' }}
    >
      <HeaderOffer type="account-banner" />
      <AccountHeader
        setMobileVisibility={setMobileVisibility}
        mobileNavOpen={mobileNavOpen}
      />
      {isBrowser() && (
        <Suspense fallback={<Loader />}>
          <div>{children}</div>
        </Suspense>
      )}
      <Footer />
    </div></ErrorBoundary>
  );
};
