import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import close from '../../../assets/close.png';
import ButtonLoader from '../../common/ButtonLoader';
import styles from './manageSubscription.module.scss';
import { RadioButton } from '../../common/Form/RadioButton';
import { navigate } from '@reach/router';

interface Props {
  closeModal: () => void;
  cancelSubscription: Function;
  show: boolean;
  subscriptionId: number;
}

const data = [
  {
    title: 'Change order frequency',
    desc: `Are you running out of food a little too early? Or
    perhaps need more time between deliveries? You can
    adjust the frequency of your orders to make it just right
    for your fur-family!`,
    btnTitle: 'Modify frequency',
    image: require('../../../assets/deliveryTruck.png'),
    onClick: (id: number) =>
      navigate(`/account/dashboard/managesubscription/${id}`),
  },
  {
    title: 'Change order date',
    desc: `Heading away on holiday? Need a few more days before
    your order processes? You can easily change the date of
    your next order right up until it processes.`,
    btnTitle: 'Change order date',
    image: require('../../../assets/calendar.png'),
    onClick: (id: number) =>
      navigate(`/account/dashboard/managedelivery/${id}`),
  },
  {
    title: 'Pause your orders',
    desc: `Need a little break? You can easily pause your orders
    and then resume them when you are ready. This is done
    by going to your dashboard and clicking the
    "Manage/Pause My Order".`,
    btnTitle: `Pause your orders`,
    image: require('../../../assets/pause.png'),
    onClick: (id: number) =>
      navigate(`/account/dashboard/managesubscription/${id}`),
  },
  {
    title: 'Need some help?',
    desc: `If you need any help or advice regarding your
    subscription or anything else please contact us. We'd
    love to help!`,
    btnTitle: 'Get help',
    image: require('../../../assets/phone.png'),
    onClick: () => navigate(`/support`),
  },
];

const reasons = [
  'Too expensive',
  "Dog didn't like it",
  'Dog passed away.',
  'Vet recommended alternative food',
  'Allergies',
  'Other',
];

const CancelSubscriptionComponent: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [otherActive, setOtherActive] = useState(false);
  const [step, setStep] = useState(1);

  const cancelSubscription = () => {
    setLoading(true);
    props.cancelSubscription(otherReason || cancellationReason);
  };

  const handleInputChange = event => {
    const target = event.target as HTMLInputElement;
    if (!otherActive) {
      setOtherActive(true);
    }
    setCancellationReason('');
    setOtherReason(target.value);
  };

  const onRadioSelect = value => {
    if (value === 'Other') {
      setOtherActive(true);
    } else {
      setOtherActive(false);
      setOtherReason('');
    }
    setCancellationReason(value);
  };

  const renderPreCancellation = () => {
    return (
      <div className="cust-modal-content">
        <div className="hdr text-center">
          <h2 className="mgn-bot-20">Are you sure?</h2>
          <p className={styles.modalText}>
            Your subscription is completely personalised and flexible...
            <br />
            Sometimes it just takes a little adjusting to make it perfect for
            your fur-family.
            <br />
            Here are some other ways that we can help you.
          </p>
        </div>
        <div className={styles.contentWrapper}>
          {data.map((item, index) => (
            <div className={styles.optionItem} key={index}>
              <img src={item.image} className={styles.optionImg} />
              <div className={styles.optionDesc}>
                <h6>{item.title}</h6>
                <p className={styles.descText}>{item.desc}</p>
                <div className="text-center mgn-top-10">
                  <ButtonLoader
                    className={styles.optionBtn}
                    text={item.btnTitle}
                    onClick={() => {
                      props.closeModal();
                      item.onClick(props.subscriptionId);
                    }}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="hdr text-center">
            <p className={styles.modalText}>
              We’ll be sad to see you go, so we'd love to help you out if we
              can!
            </p>
            <ButtonLoader
              className={styles.confirmBtn}
              text="Confirm Cancel"
              onClick={() => setStep(2)}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderConfirmCancellation = () => {
    return (
      <div className="cust-modal-content text-center">
        <img src={require('../../../assets/dog.png')} width={80} height={80} />
        <div className="hdr text-center">
          <h4 className="mgn-bot-10">Bye for now!</h4>
          <p className={styles.modalText}>
            We're sad to see you go but we wish you all the best.
            <br />
            If you don’t mind I would really like to understand a little more
            <br />
            about your choice so we can continually monitor and improve.
            <br />
            Can you please let us know your reasons for saying goodbye.
          </p>
        </div>
        <div className={styles.reasonListWrapper}>
          {reasons.map((item, index) => (
            <Form key={item}>
              <Form.Group controlId="" className={styles.formWrapper}>
                <RadioButton
                  name="reason"
                  id={index.toString()}
                  value={item}
                  checked={
                    item === 'Other' ? otherActive : cancellationReason === item
                  }
                  onChange={() => onRadioSelect(item)}
                  label={item}
                  className={item === 'Other' ? styles.radio : undefined}
                />
                {item === 'Other' ? (
                  <Form.Control
                    type="text"
                    name="cancellation_reason"
                    placeholder=""
                    value={otherReason}
                    onChange={handleInputChange}
                  />
                ) : (
                  undefined
                )}
              </Form.Group>
            </Form>
          ))}
        </div>
        <div className="hdr text-center mgn-top-10">
          <ButtonLoader
            className={styles.confirmBtn}
            text="Confirm Cancel"
            onClick={cancelSubscription}
            loading={loading}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal show={props.show} onHide={props.closeModal} size="lg">
      <Modal.Body>
        <div className="cust-modal-content-wrapper">
          <div className="modal-close pos-rel">
            <img onClick={e => props.closeModal()} src={close} />
          </div>
          {step === 1 ? renderPreCancellation() : renderConfirmCancellation()}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelSubscriptionComponent;
