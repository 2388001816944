import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getOrder } from 'services/orders.api';
import icn_picked from 'assets/icn_picked.png';
import icn_packed from 'assets/icn_packed.png';
import icn_dispatched from 'assets/icn_dispatched.png';
import icn_delivered from 'assets/icn_delivered_2.png';
import {
  formatDate,
  getDateString,
  diffInDays,
  fetchIdFromUrl,
  getProductInfoFromId,
  calculateNextOrder,
} from '../../../utils';
import { useSubscriptionContext } from '../../../pages/account';
import styles from '../dashboard.module.scss';
import { navigate } from 'gatsby';
import ShipToday from '../ManageDeliveries/ShipToday';
import { Pet, Order, Subscription } from 'models';
import { Button } from 'react-bootstrap';

interface Props {
  id?: number;
  resumeSubscription?: Function;
  subscriptionId?: number;
  nextOrderDate?: string;
  pet: Pet;
  subscriptionData?: Subscription;
}

enum StatusType {
  PLACED = 'placed',
  PACKED = 'packed',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
}

const StatusItem = ({ imgSrc, label, date }) => {
  return (
    <>
      <div className="img-wrap">
        <img src={imgSrc} alt="" />
      </div>
      <div className="line1 text-center">
        <span>{label}</span>
      </div>
      <div className="line2 text-center">
        {typeof date !== 'undefined' ? date : ''}
      </div>
    </>
  );
};

export const getORN = (orderRefNumber: string) => {
  let refNumArr: string[] = [];
  if (orderRefNumber) {
    if (orderRefNumber.indexOf(',') !== -1) {
      refNumArr = orderRefNumber.split(',');
    } else {
      refNumArr.push(orderRefNumber);
    }
  }
  return (
    <>
      {refNumArr.length
        ? refNumArr.map((refNum, index) =>
            refNum ? (
              <a key={"refNumArr-refNum"+index}
                target="_blank"
                href={'https://www.nzpost.co.nz/tools/tracking/item/' + refNum}
              >
                {index > 0 ? ', ' : ''}
                {refNum}
              </a>
            ) : null,
          )
        : null}
    </>
  );
};

export const OrderStatus: React.FC<Props> = props => {
  const [showShipToday, setShowShipToday] = useState(false);
  const { getSubscriptionById, allProducts } = useSubscriptionContext();
  const [order, setOrder] = useState({} as Order);

  const showManageDelivery = () => {
    navigate(`/account/dashboard/managedelivery/${props.subscriptionId}`);
  };
  const subscription = getSubscriptionById(props.subscriptionId);
  if (!subscription) return <></>;

  useEffect(() => {
    (async () => {
      let orderResponse = await getOrder(props.id);
      if (orderResponse.count === 1) {
        setOrder(orderResponse.results[0]);
      }
    })();
  }, [props.id]);

  const status = order.status_logs || {};

  const statusTypes = Object.keys(status) as StatusType[];
  const currentStatus = statusTypes[statusTypes.length - 1];
  const getCircClassNames = (trackingStatus: StatusType) =>
    classNames({
      circ: true,
      active: !!status[trackingStatus],
      current: currentStatus === trackingStatus,
    });

  const getDashClassNames = (
    trackingStatus: StatusType,
    responsiveClasses = {},
  ) =>
    classNames({
      ...responsiveClasses,
      dash: true,
      active: status[trackingStatus],
    });

  const nonPetLiveCheck = () => {
    if (props.pet && props.pet.id) {
      return true;
    } else {
      let atLeastOneProductLive = false;
      if (subscription && subscription.products) {
        for (let i = 0; i < subscription.products.length; i++) {
          const productId = fetchIdFromUrl(subscription.products[i].product);
          const product = getProductInfoFromId(allProducts, productId);
          if (product && product.is_public) {
            atLeastOneProductLive = true;
            break;
          }
        }
      }

      return atLeastOneProductLive;
    }
  };

  const hasTrackingInfo = Object.keys(status).length;
  const orderPlacedDate = status?.placed;
  const orderDeliveredDate = status?.delivered
    ? new Date(status?.delivered)
    : new Date();
  const today = formatDate(new Date(), 'yyyy-mm-dd');
  const showShipTodayLink = nonPetLiveCheck() && orderPlacedDate !== today;
  const deliveredSince = diffInDays(new Date(), orderDeliveredDate);
  const lastStatusUpdatedDate = status[currentStatus]
    ? new Date(status[currentStatus])
    : new Date();
  const lastStatusUpdatedSince = diffInDays(new Date(), lastStatusUpdatedDate);

  return (
    <>
      {hasTrackingInfo && deliveredSince < 2 && lastStatusUpdatedSince < 10 ? (
        <div className="fmf-card-body">
          <div className="del-card-wrapper">
            <div className="row">
              <div className="col-lg-9">
                <div className="del-prog-wrapper clearfix">
                  <div className={getCircClassNames(StatusType.PLACED)}>
                    <StatusItem
                      imgSrc={icn_picked}
                      label="Placed"
                      date={formatDate(status[StatusType.PLACED])}
                    />
                  </div>
                  <div className={getDashClassNames(StatusType.PACKED)} />
                  <div className={getCircClassNames(StatusType.PACKED)}>
                    <StatusItem
                      imgSrc={icn_packed}
                      label="Packed"
                      date={formatDate(status[StatusType.PACKED])}
                    />
                  </div>
                  <div
                    className={getDashClassNames(StatusType.SHIPPED, {
                      'col-hide-md': true,
                    })}
                  />
                  <div
                    className={getDashClassNames(StatusType.SHIPPED, {
                      'col-hide-lg': true,
                      'dash-mob-or': true,
                      'mgn-bot-10': true,
                    })}
                  >
                    <div className="dash-mob" />
                  </div>
                  <div className={getCircClassNames(StatusType.SHIPPED)}>
                    <StatusItem
                      imgSrc={icn_dispatched}
                      label="Dispatched"
                      date={formatDate(status[StatusType.SHIPPED])}
                    />
                  </div>
                  <div className={getDashClassNames(StatusType.DELIVERED)} />
                  <div className={getCircClassNames(StatusType.DELIVERED)}>
                    <StatusItem
                      imgSrc={icn_delivered}
                      label="Delivered"
                      date={formatDate(status[StatusType.DELIVERED])}
                    />
                  </div>
                </div>
                {order.tracking_reference && (
                  <p className="tracking-ref">
                    Tracking reference number:{' '}
                    {getORN(order.tracking_reference)}
                  </p>
                )}
              </div>
              <div className="col-lg-3">
                <div className="mng-btns">
                  <div className="mng-btn">
                    <div
                      className="btn-item-warn mgn-top-10"
                      onClick={showManageDelivery}
                    >
                      Change Date
                    </div>
                    {showShipTodayLink && (
                      <div className="btn-item-warn mgn-top-10">
                        <Button
                          className="btn-small"
                          onClick={() => setShowShipToday(true)}
                        >
                          Reorder Now
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="fmf-card-body">
          <div className="del-card-wrapper">
            <div className="row">
              <div className="col-lg-9">
                {/* <div className="del-prog-wrapper clearfix"> */}
                <div className={styles.noTrackingContainer}>
                  <div className={styles.delieveryTruck} />
                  <p>
                    You don't currently have an order out for delivery. <br />
                    {props.subscriptionData?.is_active ? (
                      <div>
                        Your next order will be on its way on
                        <b>
                          {' '}
                          {getDateString(
                            !props.subscriptionData.skip_next
                              ? props.subscriptionData.next_order
                              : calculateNextOrder(
                                  props.subscriptionData.next_order,
                                  props.subscriptionData.frequency,
                                ),
                          )}
                        </b>
                        .
                      </div>
                    ) : (
                      <>
                        Your subscription is currently on pause and no future
                        orders will process until you{' '}
                        <a
                          href={`/account/dashboard/managesubscription/${props.subscriptionData?.id}`}
                        >
                          resume your deliveries
                        </a>
                        .
                      </>
                    )}
                  </p>
                </div>
                {/* </div> */}
              </div>
              <div className="col-lg-3">
                <div className="mng-btns">
                  <div className="mng-btn">
                    <div
                      className="btn-item-warn mgn-top-10"
                      onClick={showManageDelivery}
                    >
                      Change Date
                    </div>
                    {showShipTodayLink && (
                      <div
                        className="btn-item-warn mgn-top-10"
                        onClick={() => setShowShipToday(true)}
                      >
                        <Button
                          className="btn-small"
                          onClick={() => setShowShipToday(true)}
                        >
                          Reorder Now
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ShipToday
        closeModal={() => setShowShipToday(false)}
        show={showShipToday}
        subscription={subscription}
        petDetails={props.pet}
      />
    </>
  );
};

export default OrderStatus;
