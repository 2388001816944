import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Pets } from './Pets';
import { MySubscriptions } from './MySubscriptions';
import { SubscriptionDeliveries } from './SubscriptionDeliveries';
import { navigate } from '@reach/router';
import { useNotificationToastContext } from '../common/NotificationToast';
import { deleteAllPetsList } from '../../utils';

export const Dashboard = ({ location, allProducts }) => {
  const { showNotificationToast } = useNotificationToastContext();

  useEffect(() => {
    if (location.state && location.state.notificationText) {
      showNotificationToast(location.state.notificationText);
    }
  }, []);

  return (
    <div className="page-wrapper">
      <div className="dashboard-wrapper">
        <div className="dash-banner">
          <div className="container-fluid">
            <div className="def-w-max">
              <div className="row">
                <div className="col dash-col-or">
                  <div className="hdr mgn-top-30">
                    <h2>Dashboard</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dash-contents">
          <div className="container-fluid">
            <div className="def-w-max">
              <SubscriptionDeliveries />
              <MySubscriptions  />
              <Pets allProducts={allProducts} />
              <div className="su-btn bt-wrap1 text-center btn-wrap-hlt">
                <Button
                  className="btn-def"
                  onClick={() => {
                    deleteAllPetsList();
                    navigate('/account/dashboard/addpet');
                  }}
                >
                  +Add a new Furbaby
                </Button>
                <Button
                  className="btn-def btn-lt mgn-md-lt-1 mgn-sm-left-0 mgn-sm-top-2"
                  onClick={() => {
                    deleteAllPetsList();
                    navigate('/products');
                  }}
                >
                  Buy Furbaby favorites
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default props => <>{props.children}</>;
