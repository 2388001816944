import axiosCustom from './customAxios';
import { Subscription } from './models';

const petPath = 'subscription/';
const nonPetPath = 'usersubscription/';
const path = 'subscriptions/';

export const getUserSubscription = async (): Promise<Subscription[]> => {
  const { data, error } = await axiosCustom.get(path);
  if (error) throw error;
  return data;
};

export const cancelSubscription = async (subscriptionId, payload, petId) => {
  const apiPath = petId ? petPath : nonPetPath;
  const { data, error } = await axiosCustom.delete(
    `${apiPath + subscriptionId}/`,
    {
      data: payload,
    },
  );
  if (error) {
    throw error;
  }
  return data;
};

export const updateSubscription = async (
  subscriptionId,
  payload,
  petId,
): Promise<Subscription> => {
  const apiPath = petId ? petPath : nonPetPath;
  const { data, error } = await axiosCustom.put(
    `${apiPath + subscriptionId}/`,
    payload,
  );
  if (error) {
    throw error;
  }
  return data;
};

export const shipToday = async (subscriptionId: number, petId?: number) => {
  const apiPath = petId ? petPath : nonPetPath;
  const { data, error } = await axiosCustom.get(
    `${apiPath + subscriptionId}/ship-today/`,
  );
  if (error) {
    throw error;
  }
  return data;
};

export const getSubscriptionDiscountValues = async (
  subscriptionId: number,
  frequency: number,
  petId?: number,
) => {
  const apiPath = petId ? petPath : nonPetPath;
  const { data, error } = await axiosCustom.get(
    `${apiPath + subscriptionId}/discount/?frequency=${frequency}`,
  );
  if (error) {
    throw error;
  }
  return data;
};
