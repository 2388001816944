import React, { useState } from 'react';
import { SubscriptionComponent } from './Subscription';
import { useSubscriptionContext } from '../../../pages/account';
import { ResumeSubscriptionModal } from '../ManageSubscription/ResumeSubscriptionModal';
import { PauseDeliveryModal } from '../ManageSubscription/PauseDeliveryModal';
import styles from './mySubscription.module.scss';
import { setDays, formatDate } from '../../../utils';
import { useNotificationToastContext } from '../../common/NotificationToast';

export const MySubscriptions = () => {
  const {
    subscriptions,
    updateSubscriptionData,
    getPetBySubscriptionId,
  } = useSubscriptionContext();
  const {
    showNotificationToast,
    hideNotificationToast,
  } = useNotificationToastContext();
  const [showPauseModal, setPauseModalVisibilty] = useState({
    subscription: {},
    show: false,
  });
  const [resumeModalState, setResumeModalState] = useState({
    subscriptionId: 0,
    show: false,
  });

  const handlePause = async ({ pauseTime, pauseReason }) => {
    const payload =
      pauseTime !== -1
        ? {
            why_pausing: pauseReason,
            is_active: false,
            paused_with_duration: true,
            next_order: setDays(
              pauseTime,
              'yyyy-mm-dd',
              showPauseModal.subscription.next_order,
            ),
            paused_date: formatDate(new Date(), 'yyyy-mm-dd'),
          }
        : {
            why_pausing: pauseReason,
            is_active: false,
            paused_date: formatDate(new Date(), 'yyyy-mm-dd'),
          };
    await updateSubscriptionData(
      payload,
      showPauseModal.subscription.id,
      getPetBySubscriptionId(showPauseModal.subscription.id).id,
    );
    setPauseModalVisibilty({
      ...showPauseModal,
      show: false,
    });
    showNotificationToast('Your subscription has been paused successfully');
  };
  return (
    <>
      <div className="fmf-card">
        <div className="fmf-card-hdr clearfix">
          <div className="float-left">
            <span>My Subscriptions</span>
          </div>
        </div>
        {subscriptions.map(subscription => (
          <div className={styles.splitBox}>
            <SubscriptionComponent
              key={subscription.id}
              subscription={subscription}
              pauseSubscription={() => {
                setPauseModalVisibilty({
                  show: true,
                  subscription: subscription,
                });
                hideNotificationToast();
              }}
              resumeSubscription={() => {
                setResumeModalState({
                  show: true,
                  subscriptionId: subscription.id,
                });
                hideNotificationToast();
              }}
            />
          </div>
        ))}
        {!subscriptions.length && (
          <div className={styles.emptySubscription}>
            <div className={styles.emptySubscriptionChild}>
              <span className={styles.emptySubscriptionIcon} />
              <h2>Nice to have you here !</h2>
              <p>
                You do not have any subscription currently, so it’s a perfect
                day to start.{' '}
              </p>
            </div>
          </div>
        )}
      </div>
      <ResumeSubscriptionModal
        closeModal={() => {
          setResumeModalState({
            ...resumeModalState,
            show: false,
          });
        }}
        onResumed={() => {
          showNotificationToast(
            'Your subscription has been resumed successfully',
          );
        }}
        subscriptionId={resumeModalState.subscriptionId}
        show={resumeModalState.show}
      />
      {showPauseModal.show && (
        <PauseDeliveryModal
          show={showPauseModal.show}
          closeModal={() => {
            setPauseModalVisibilty({
              ...showPauseModal,
              show: false,
            });
          }}
          onSubmit={handlePause}
          subscription={showPauseModal.subscription}
        />
      )}
    </>
  );
  //}
};
