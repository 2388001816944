import React from 'react';
import styles from './index.module.scss';

export const Tooltip = props => {
  return (
    <div className={styles.tooltip} style={{ marginRight: props.marginRight }}>
      {props.children}
      <span className={styles.tooltiptext} style={{ top: `${props.top}%` }}>
        {props.text}
      </span>
    </div>
  );
};
