import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonLoader from '../../common/ButtonLoader';
import close from '../../../assets/close.png';

interface ModalProps {
  show: boolean;
  id: number;
  name: string;
  closeModal: Function;
  deleteProfile: Function;
  isLoading: boolean;
}

export const DeleteModal: React.FC<ModalProps> = props => {
  return (
    <Modal show={props.show} onHide={props.closeModal} size="lg">
      <Modal.Body>
        <div className="cust-modal-content-wrapper">
          <div className="modal-close pos-rel">
            <img onClick={props.closeModal} src={close} />
          </div>
          <div className="cust-modal-content">
            <div className="hdr text-center">
              <h2 className="mgn-bot-20">
                Are you sure you want to delete {props.name}'s Profile ?
              </h2>
            </div>
            <div className="text-center cust-modal-footer mgn-top-50">
              <ButtonLoader
                className="btn-def"
                onClick={() => props.deleteProfile()}
                loading={props.isLoading}
                text="Yes, Delete"
              />

              <Button
                className="btn-txt btn-txt-org btn btn-primary"
                onClick={() => props.closeModal()}
              >
                No, I don’t want to delete
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
