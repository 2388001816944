import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PauseDuration } from './PauseDuration';
import { PauseReason } from './PauseReason';
import { ConfirmPause } from './ConfirmPause';
import close from '../../../../assets/close.png';
import { Subscription } from '../../../../services/models';

interface Props {
  show: boolean;
  closeModal: Function;
  onSubmit: Function;
  subscription: Subscription;
}

export const PauseDeliveryModal: React.FC<Props> = props => {
  const [pauseTime, setPauseTime] = useState(14);
  const [stepStatus, setStepStatus] = useState(1);

  const handleChange = e => {
    const value = e.target.value;
    setPauseTime(Number(value));
  };

  const saveChanges = pauseReason => {
    props.onSubmit({
      pauseTime,
      pauseReason,
    });
  };

  const getModalContent = () => {
    switch (stepStatus) {
      case 1:
        return (
          <PauseDuration
            handleChange={handleChange}
            pauseTime={pauseTime}
            onSave={() => setStepStatus(2)}
            onCancel={() => props.closeModal()}
          />
        );
      case 2:
        return (
          <ConfirmPause
            pauseTime={pauseTime}
            nextOrderDate={props.subscription.next_order}
            onGoBack={() => setStepStatus(1)}
            onConfirm={() => setStepStatus(3)}
            onCancel={() => props.closeModal()}
          />
        );
      case 3:
        return (
          <PauseReason
            onSave={saveChanges}
            onCancel={() => props.closeModal()}
          />
        );
      default:
        break;
    }
  };
  return (
    <Modal show={props.show} onHide={props.closeModal}>
      <Modal.Body>
        <div className="cust-modal-content-wrapper">
          <div className="modal-close pos-rel">
            <img onClick={e => props.closeModal()} src={close} alt="" />
            <div className="cust-modal-content">
              <div className="hdr text-center">
                <h2 className="mgn-bot-40">Pause Deliveries</h2>
              </div>
              {getModalContent()}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
