import React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from '../services/auth.api';

interface Props {
  Component: any;
  path: string;
  children?: any;
}

export default ({ Component, ...rest }: Props) => {
  if (!isLoggedIn() && location.pathname !== `/login`) {
    navigate('/login');
    return null;
  }

  return <Component {...rest} />;
};
