import React from 'react';
import { OrderStatus } from './OrderStatus';
import styles from './subscriptions.module.scss';
import box from '../../../assets/box_new.jpg';
import { getProductInfoFromId, getProductImageUrl } from '../../../utils';
import { Subscription, Pet, ProductInfo } from '../../../services/models';
import { useSubscriptionContext } from '../../../pages/account';
import { Tooltip } from '../../common/Tooltip';

interface SubscriptionComponentPropsType {
  subscription: Subscription;
  pet: Pet;
  allProducts: ProductInfo[];
}

const SubscriptionComponent = (props: SubscriptionComponentPropsType) => {
  const { allProducts, subscription, pet } = props;

  const frequency = subscription.frequency;
  const daysToNextOrder = subscription.days_left;

  const foodLeftPercentage = (daysToNextOrder * 100) / frequency;
  const height = foodLeftPercentage > 100 ? 100 : foodLeftPercentage;

  const productData = getProductInfoFromId(allProducts, subscription.product);
  const currentProductData = getProductInfoFromId(allProducts, subscription.current_product);

  const formNibble = () => {
    return (
      <Tooltip
        text={`${daysToNextOrder} days`}
        top={100 - height - 12}
        marginRight={60}
      >
        <div className={styles.measuringBar}>
          <div
            style={{
              height: `${height}%`,
            }}
          />
        </div>
      </Tooltip>
    );
  };

  const formPetSection = () => {
    return (
      <div
        id={subscription.id.toString()}
        className={styles.subscriptionSupply}
      >
        <div className={styles.title}>
          <span className="txt-capitalize">{pet.name}</span>'s Supply
        </div>
        <div className={styles.foodMeasureContainer}>
          <div className={styles.foodSection}>
            <div
              className={styles.productImage}
              style={{
                backgroundImage: `url(
                  "${currentProductData?.image ? 
                    getProductImageUrl(currentProductData?.image) : productData?.image ? getProductImageUrl(productData?.image) : box }")`,

              }}
            ></div>
          </div>
          {formNibble()}
        </div>
        <p className={styles.nibbleInfo}>
          You have{' '}
          <b>
            {subscription.box_count}x {currentProductData?.title ? currentProductData?.title : productData.title}
          </b>{' '}
          with
          <b> {daysToNextOrder} days</b> of kibble left
        </p>
      </div>
    );
  };

  const formNonPetSection = () => {
    return (
      <div
        id={subscription.id.toString()}
        className={styles.subscriptionSupply}
      >
        <div className={styles.title}>
          Sides Subscription
          {subscription.display_id && subscription.display_id > 1
            ? ' ' + subscription.display_id
            : ''}
        </div>
        <div className={styles.foodMeasureContainer}>
          <div className={styles.foodSection}>
            {subscription.products?.map(product => (
              <p>
                {product.quantity}x <b>{product.product_name}</b>
              </p>
            ))}
          </div>
          {formNibble()}
        </div>
        <p className={styles.nibbleInfo}>
          You have <b> {daysToNextOrder} days</b> left until next order
        </p>
      </div>
    );
  };

  return pet ? formPetSection() : formNonPetSection();
};

const UpComingDelivery = props => {
  const { pet, subscription } = props;

  const showOrderId = () => {
    if (subscription.last_order_id) {
      return <>&nbsp;- Order {subscription.last_order_id}</>;
    }
  };

  const getRequiredMinHeight = () => {
    const supplyElementHeight = document.getElementById(subscription.id)
      ?.clientHeight;
    return supplyElementHeight ? supplyElementHeight : 275.94;
  };

  return (
    <div
      style={{ minHeight: getRequiredMinHeight() }}
      className={styles.subscriptionDelivery}
    >
      <div className={styles.title}>
        {subscription.pet ? (
          <span>
            <span className="txt-capitalize">{pet.name}</span>'s Delivery
          </span>
        ) : (
          <span>
            Subscription
            {subscription.display_id && subscription.display_id > 1
              ? ' ' + subscription.display_id + ' '
              : ' '}
            Delivery
          </span>
        )}
        {showOrderId()}
      </div>
      <OrderStatus
        subscriptionId={props.subscription.id}
        id={props.subscription.last_order_id}
        nextOrderDate={props.subscription.next_order}
        pet={pet}
        subscriptionData={subscription}
      />
    </div>
  );
};

export const SubscriptionDeliveries = () => {
  const { subscriptions, pets, allProducts } = useSubscriptionContext();
  const petSubscriptionMap = subscriptions.reduce((prev, subscription) => {
    if (subscription.pet) {
      const petId = subscription.pet.match(/\/(\d+)\/$/)[1];
      const pet = pets.find(pet => pet.id === parseInt(petId));
      prev[subscription.id] = pet || {};
    }
    return prev;
  }, {});

  if (!subscriptions.length) {
    return <></>;
  }

  return (
    <div className="row fmf-card">
      <div className="col-12 col-xl-4">
        <div className={styles.header}>Food Supply</div>
        <div className={styles.innerContainer}>
          {subscriptions.map((subscription, index) => {
            const showSeperator = index < subscriptions.length - 1;
            return (
              <React.Fragment key={subscription.id}>
                <SubscriptionComponent
                  subscription={subscription}
                  allProducts={allProducts}
                  pet={petSubscriptionMap[subscription.id]}
                />
                {showSeperator && <div className={styles.seperator} />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="col-12 col-xl-8">
        <div className={styles.header}>My Deliveries</div>
        <div className={styles.innerContainer}>
          {subscriptions.map((subscription, index) => {
            const showSeperator = index < subscriptions.length - 1;
            return (
              <React.Fragment key={subscription.id}>
                <UpComingDelivery
                  subscription={subscription}
                  pet={petSubscriptionMap[subscription.id]}
                />
                {/* <p className={styles.note}>
                  <br /> */}
                {/* Your next order will be placed on
                  <b> {getDateString(subscription.next_order)}.</b> */}
                {/* </p> */}
                {showSeperator && <div className={styles.seperator} />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
