import axiosCustom from './customAxios';
import { SubscriptionTreats } from './models/subscriptionTreats';

const petPath = 'subscription/{subscriptionId}/treats/{id}/';
const nonPetPath = 'usersubscription/{subscriptionId}/products/{id}/';
const petPathCreate = 'subscription/{subscriptionId}/addtreat/';
const nonPetPathCreate = 'usersubscription/{subscriptionId}/addproduct/';

export const getSubscriptionTreat = async (
  subscriptionId: number,
  id: number,
  pet: number,
): Promise<SubscriptionTreats> => {
  const apiPath = pet ? petPath : nonPetPath;
  const { data, error } = await axiosCustom.get(
    apiPath
      .replace('{subscriptionId}', subscriptionId.toString())
      .replace('{id}', id.toString()),
  );
  if (error) throw error;
  return data;
};

export const deleteSubscriptionTreat = async (
  subscriptionId: number,
  id: number,
  pet: number,
): Promise<any> => {
  const apiPath = pet ? petPath : nonPetPath;
  const { data, error } = await axiosCustom.delete(
    apiPath
      .replace('{subscriptionId}', subscriptionId.toString())
      .replace('{id}', id.toString()),
  );
  if (error) throw error;
  return data;
};

export const updateSubscriptionTreat = async (
  subscriptionId: number,
  product: string,
  quantity: number,
  id: number,
  pet: number,
  is_once: boolean,
): Promise<SubscriptionTreats> => {
  const apiPath = pet ? petPath : nonPetPath;
  const { data, error } = await axiosCustom.put(
    apiPath
      .replace('{subscriptionId}', subscriptionId.toString())
      .replace('{id}', id.toString()),
    {
      pet,
      product,
      quantity,
      is_once,
    },
  );
  if (error) throw error;
  return data;
};

export const createSubscriptionTreat = async (
  subscriptionId: number,
  product: string,
  quantity: number,
  pet: number,
  is_once: boolean,
): Promise<SubscriptionTreats> => {
  const apiPath = pet ? petPathCreate : nonPetPathCreate;
  const { data, error } = await axiosCustom.post(
    apiPath.replace('{subscriptionId}', subscriptionId.toString()),
    {
      pet,
      product,
      quantity,
      is_once,
    },
  );
  if (error) throw error;
  return data;
};
