import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { find } from 'lodash';
import close from '../../../assets/close.png';
import { useSubscriptionContext } from '../../../pages/account';
import ButtonLoader from '../../common/ButtonLoader';
import { RadioButton } from '../../common/Form/RadioButton';
import { getStoredCard } from 'services/card.api';
import { getUserAddress } from 'services/address.api';
import {
  shipToday,
  getSubscriptionDiscountValues,
} from 'services/subscription.api';
import { getShippingCharge } from 'services/shipping.api';
import { StoredCard, ShippingCharge } from 'services/models';
import styles from './index.module.scss';
import { Subscription, Pet, UserAddress } from 'models';
import { calculateNextOrder, getAddressDisplay } from '../../../utils';
import StatusMessage from '../../common/StatusMessage';
import { useNotificationToastContext } from '../../common/NotificationToast';
import { StatusType } from '../../../types';
import moment from 'moment';
interface Props {
  closeModal: () => void;
  show: boolean;
  subscription: Subscription;
  petDetails: Pet;
}

enum FrequencySelection {
  Current = 'current',
  New = 'new',
}

interface SubscriptionDiscountValues {
  total_excl_discount_without_shipping: number;
  total_incl_discount_without_shipping: number;
  total_incl_discounts: number;
}
const errorMessage="Apologies, pet parents! We’re currently experiencing technical issues, but rest assured, we’re actively working to resolve them. Please try refreshing the page and if the issue persists, reach us at 09 886 9738 for immediate assistance or to place your order over the phone"
const ShipToday: React.FC<Props> = props => {
  const [subscription] = useState(props.subscription);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ message: '', type: StatusType.none });
  const [state, setState] = useState({
    frequency: subscription.frequency,
    reason: '',
  });
  const [freqErr, setFreqErr] = useState('');

  const [
    frequencyDropDownVisibility,
    setFrequencyDropDownVisibility,
  ] = useState(false);

  const {
    updateSubscriptionData,
    fetchSubscriptions,
  } = useSubscriptionContext();
  const { showNotificationToast } = useNotificationToastContext();
  const [confirmModalEnabled, setConfirmModalEnabled] = useState(false);
  const [storedCard, setStoredCard] = useState<StoredCard>();
  const [defaultAddress, setDefaultAddress] = useState<UserAddress>();
  const [shippingCharges, setShippingCharges] = useState<ShippingCharge>({
    base_charge: '0',
    rural_delivery: '0',
    incl_tax: '0',
    excl_tax: '0',
  });
  const [subscriptionDiscountValues, setSubscriptionDiscountValues] = useState<
    SubscriptionDiscountValues
  >({
    total_excl_discount_without_shipping: 0.0,
    total_incl_discount_without_shipping: 0.0,
    total_incl_discounts: 0.0,
  });

  const saveDetails = async () => {
    setLoading(true);
    try {
      const newSubscription =
        updateSubscriptionData &&
        (await updateSubscriptionData(
          {
            frequency: state.frequency,
            why_shiptoday: state.reason,
          },
          subscription.id,
          props.petDetails?.id,
        ));
      await shipToday(
        newSubscription ? newSubscription.id : subscription.id,
        props.petDetails?.id,
      );
      fetchSubscriptions && (await fetchSubscriptions());
      setLoading(false);
      showNotificationToast('Order placed successfully');
      props.closeModal();
    } catch (ex) {
      setLoading(false);
      setStatus({
        message:
        errorMessage,
        type: StatusType.error,
      });
    }
  };

  const enableConfirmModal = async () => {
    setLoading(true);
    try {
      const card = await getStoredCard();
      const deliveryAddresses: UserAddress[] = await getUserAddress();
      const defaultAddr = find(
        deliveryAddresses,
        address => address.is_default_for_shipping,
      );
      const fetchedShippingCharges: ShippingCharge = defaultAddr
        ? await getShippingCharge(
            defaultAddr,
            subscription.id,
            !props.petDetails?.id,
          )
        : {
            incl_tax: '0',
            base_charge: '0',
            rural_delivery: '0',
            excl_tax: '0',
          };
      const subDiscountValues: SubscriptionDiscountValues = await getSubscriptionDiscountValues(
        subscription.id,
        state.frequency,
        props.petDetails?.id,
      );
      setStoredCard(card || {});
      setDefaultAddress(defaultAddr);
      setConfirmModalEnabled(true);
      setShippingCharges(fetchedShippingCharges);
      setSubscriptionDiscountValues({
        total_excl_discount_without_shipping:
          subDiscountValues.total_excl_discount_without_shipping ?? 0.0,
        total_incl_discount_without_shipping:
          subDiscountValues.total_incl_discount_without_shipping ?? 0.0,
        total_incl_discounts: subDiscountValues.total_incl_discounts ?? 0.0,
      });
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      setStatus({
        message:
        errorMessage,
        type: StatusType.error,
      });
    }
  };

  const onChangeFrequency = event => {
    const frequencySelection = event.target.value;
    setState({ ...state, frequency: subscription?.frequency });
    if (frequencySelection === FrequencySelection.New) {
      setFreqErr('');
      return setFrequencyDropDownVisibility(true);
    }
    return setFrequencyDropDownVisibility(false);
  };

  const handleFrequencyChange = (e: React.SyntheticEvent<{}>) => {
    let target = e.target as HTMLInputElement;
    if (Number(target.value) > 999) {
      setFreqErr('Maximum allowed value is 999');
    } else if (target.value === '') {
      setFreqErr('Frequency is a required field');
    } else if (Number(target.value) < 2) {
      setFreqErr('Minimum allowed value is 2');
    } else {
      setFreqErr('');
    }
    setState({ ...state, frequency: target.value });
  };

  const initialShipTodayContainer = () => {
    return (
      <div className={`hdr text-center ${styles.shipTodayContainer}`}>
        <h2 className="mgn-bot-40">Ship Today</h2>
        <p>
          Current frequency is{' '}
          {props.petDetails?.id ? (
            <b>
              {subscription.box_count} box every {subscription.frequency} days
            </b>
          ) : (
            <b>every {subscription.frequency} days</b>
          )}
          <br />
          Next order is on{' '}
          <b>
            {moment(
              !subscription?.skip_next
                ? subscription.next_order
                : calculateNextOrder(
                    subscription.next_order,
                    subscription.frequency,
                  ),
              'YYYY-MM-DD',
            ).format('DD-MM-YYYY')}
          </b>
        </p>
        <p>Please let us know your preferences</p>
        <div className="row">
          <RadioButton
            name="frequency"
            id="currentfrequency"
            checked={!frequencyDropDownVisibility}
            value={FrequencySelection.Current}
            onChange={onChangeFrequency}
            label="Maintain current frequency"
          />
          <RadioButton
            name="frequency"
            id="newfrequency"
            checked={frequencyDropDownVisibility}
            value={FrequencySelection.New}
            onChange={onChangeFrequency}
            label="Change Frequency"
          />
        </div>
        {frequencyDropDownVisibility && (
          <div className="mgn-top-20">
            <Form.Control
              as="input"
              type="number"
              onChange={handleFrequencyChange}
              value={state.frequency}
              name="frequency"
              className="cust-sel"
            />
            {freqErr && (
              <div style={{ float: 'left', marginBottom: 30, color: 'red' }}>
                {freqErr}
              </div>
            )}
          </div>
        )}
        <Form.Control
          placeholder="Hey, we'd love to understand your reason for asking to ship Today.."
          as="input"
          onChange={e => setState({ ...state, reason: e.target.value })}
          value={state.reason}
          name="reason"
          className="cust-sel mgn-top-20"
        />
        {frequencyDropDownVisibility && (
          <p className="mgn-top-20">
            Please note: changing the volume or frequency of your delivery may
            change your price.
          </p>
        )}
      </div>
    );
  };

  const confirmShipTodayContainer = () => {
    const subTotalWithoutShipping =
      subscriptionDiscountValues.total_excl_discount_without_shipping;
    const subTotalWithShipping = Number(
      (subTotalWithoutShipping + +shippingCharges.base_charge).toFixed(2),
    );
    const ruralDeliveryCharges = shippingCharges.rural_delivery
      ? shippingCharges.rural_delivery
      : '0.0';
    return (
      <div className={`hdr text-center ${styles.shipTodayContainer}`}>
        <h2 className="mgn-bot-40">Ship Today</h2>
        <p>
          {'This will authorise a transaction to be charged to your saved card'}
          <b>
            {' XXXX-XXXX-XXXX-' +
              (storedCard?.last4 ? storedCard?.last4 + '' : 'XXXX')}
          </b>
          {' and shipped to your saved default delivery address'}
          {defaultAddress ? (
            <>
              <b>{' ' + getAddressDisplay(defaultAddress)}</b> {'. '}
            </>
          ) : (
            '. '
          )}

          <br />
          {'Your order subtotal is'}
          <b className="mgn-rgt-5 mgn-lft-5">{'$' + subTotalWithoutShipping}</b>
          {'. '}

          {subscriptionDiscountValues.total_incl_discounts > 0 &&
          subscriptionDiscountValues.total_incl_discounts <
            subTotalWithShipping + +ruralDeliveryCharges ? (
            <>
              {'You have a discount of'}
              <b className="mgn-rgt-5 mgn-lft-5">
                {'$' +
                  (
                    subTotalWithShipping +
                    +ruralDeliveryCharges -
                    subscriptionDiscountValues.total_incl_discounts
                  ).toFixed(2)}
              </b>
              {'.'}
            </>
          ) : null}
          <br />
          {shippingCharges.base_charge && +shippingCharges.base_charge > 0 ? (
            <>
              {'After adding the shipping cost'}
              <b className="mgn-rgt-5 mgn-lft-5">
                {'$' + shippingCharges.base_charge}
              </b>
            </>
          ) : (
            <>
              {'After adding'}
              <b>{' FREE '}</b> {'shipping'}
            </>
          )}
          {+ruralDeliveryCharges > 0 ? (
            <>
              {' and rural delivery charges'}
              <b className="mgn-rgt-5 mgn-lft-5">
                {'$' + ruralDeliveryCharges}
              </b>
            </>
          ) : null}
          {', your order total will be'}
          <b className="mgn-rgt-5 mgn-lft-5">
            {'$' +
              (subscriptionDiscountValues.total_incl_discounts > 0 &&
              subscriptionDiscountValues.total_incl_discounts <
                subTotalWithShipping + +ruralDeliveryCharges
                ? subscriptionDiscountValues.total_incl_discounts
                : subTotalWithShipping + +ruralDeliveryCharges
              ).toFixed(2)}
          </b>
          {'.'}
        </p>
      </div>
    );
  };

  const onModalExited = () => {
    setState({ ...state, frequency: subscription?.frequency });
    setFrequencyDropDownVisibility(false);
    setConfirmModalEnabled(false);
    setStatus({ message: '', type: StatusType.none });
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.closeModal}
        onExited={onModalExited}
        size="lg"
      >
        <Modal.Body>
          <div className="cust-modal-content-wrapper">
            <div className="modal-close pos-rel">
              <img onClick={props.closeModal} src={close} />
            </div>
            <div className="cust-modal-content">
              {confirmModalEnabled
                ? confirmShipTodayContainer()
                : initialShipTodayContainer()}
              <StatusMessage message={status.message} type={status.type} />
              <div className="su-btn btn-min-wd text-center su-btn-100-sm mgn-top-50">
                <ButtonLoader
                  className="btn-def"
                  text={
                    status.type === StatusType.error
                      ? 'Close'
                      : confirmModalEnabled
                      ? 'Confirm'
                      : 'Ship Today'
                  }
                  onClick={
                    status.type === StatusType.error
                      ? props.closeModal
                      : confirmModalEnabled
                      ? saveDetails
                      : enableConfirmModal
                  }
                  loading={loading}
                  disabled={Boolean(freqErr) && frequencyDropDownVisibility}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShipToday;
