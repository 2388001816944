import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonLoader from '../../common/ButtonLoader';
import close from '../../../assets/close.png';

interface ModalProps {
  show: boolean;
  subscriptionId: number;
  addonId: number;
  addonName:string;
  closeModal: Function;
  removeTreat: Function;
  isLoading: boolean;
}

export const DeleteTreatModal: React.FC<ModalProps> = props => {
  return (
    <Modal show={props.show} onHide={props.closeModal} size="lg">
      <Modal.Body>
        <div className="cust-modal-content-wrapper">
          <div className="modal-close pos-rel">
            <img onClick={props.closeModal} src={close} />
          </div>
          <div className="cust-modal-content">
            <div className="hdr text-center">
              <h4 className="mgn-bot-5">
                Are you sure you want to delete <span className='color-theme'>{props.addonName}</span> from Add-ons ?
              </h4>
            </div>
            <div className="text-center cust-modal-footer mgn-top-20">
              <ButtonLoader
                className="btn-def"
                onClick={() => props.removeTreat()}
                loading={props.isLoading}
                text="Yes, please remove"
              />

              <Button
                className="btn-txt btn-txt-org btn btn-primary"
                onClick={() => props.closeModal()}
              >
                No, I’ll keep it
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
